// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "prismjs/themes/prism.css"
import "./src/styles/animate.css"
import "./src/styles/magnific-popup.css"
import "./src/styles/slick.css"
import "./src/styles/font-awesome.min.css"
import "./src/styles/LineIcons.css"
import "./src/styles/flaticon.css"
import "./src/styles/nice-select.css"
import 'bootstrap/dist/css/bootstrap.css';

import 'jquery/dist/jquery.min.js'
// import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js';

import "./src/styles/default.css"
// TEMPLATE CSS:
/*------------------------------------------------------------------------------
    Template Name: DigiMark Digital Marketing Agency HTML Template
    Author URI: https://uideck.com/
    Support: https://uideck.com/support/
    Version: 1.0
------------------------------------------------------------------------------*/
import "./src/styles/style-common.css"          // USED
// import "./src/styles/style-preloader.css"
import "./src/styles/style-header.css"          // USED
import "./src/styles/style-navbar.css"          // USED
// import "./src/styles/style-navbar2.css"
import "./src/styles/style-navbar-white.css"    // USED
// import "./src/styles/style-navbar3.css"
// import "./src/styles/style-navbar4.css"
// import "./src/styles/style-navbar5.css"
// import "./src/styles/style-navbar6.css"
// import "./src/styles/style-navbar7.css"
import "./src/styles/style-headerhero.css"      // USED
// import "./src/styles/style-headerhero2.css"
// import "./src/styles/style-headerhero3.css"
// import "./src/styles/style-headerhero4.css"
// import "./src/styles/style-headerhero5.css"
// import "./src/styles/style-headerhero6.css"
// import "./src/styles/style-brand.css"
import "./src/styles/style-about.css"           // USED
import "./src/styles/style-service.css"         // USED
// import "./src/styles/style-gallery.css"
// import "./src/styles/style-pricing.css"
import "./src/styles/style-testimonial.css"     // USED
// import "./src/styles/style-testimonial-2.css"
// import "./src/styles/style-testimonial-3.css"
// import "./src/styles/style-testimonial-6.css"
// import "./src/styles/style-footer.css"       // MODIFIED + TRANSFERRED: Footer.scss
import "./src/styles/style-newsletter.css"      // USED
// import "./src/styles/style-pagebanner.css"
import "./src/styles/style-team.css"            // USED
import "./src/styles/style-faq.css"             // USED
// import "./src/styles/style-process.css" 
// import "./src/styles/style-signin.css" 
// import "./src/styles/style-portfolio.css" 
import "./src/styles/style-blog.css"        // USED
import "./src/styles/style-contact.css"     // USED
// import "./src/styles/style-error.css" 
// import "./src/styles/style-domain.css" 
import "./src/styles/style-analysis.css"    // USED
import "./src/styles/style-project.css"     // USED
// import "./src/styles/style-appdownload.css" 
// import "./src/styles/style-history.css"
import "./src/styles/style-cta.css"         // USED

// LEXIQA CSS:
import "./src/styles/lexiqa.scss"

// INCLUDE JQUERY:
const $ = require("jquery")

export const onInitialClientRender = () => {}

// Page Change Handler
export const onRouteUpdate = ({ location }) => {
    
    console.log('Event::RouteUpdated');

    $(function() {
    
        window.$ = $;
    
        //== PRODUCT UPDATES PAGE ==//

        // Show first Version section
        $(".updates-content h2").eq(0).addClass("open");
        $(".version-container").eq(0).toggle();
    
        // On Version title click, show content
        $(".updates-content h2").on( "click", function(){
            console.log("clicked!");
            $(this).toggleClass("open").next().next().slideToggle(600);
        });
    
    });

}