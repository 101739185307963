// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-base-js": () => import("./../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-blog-de-js": () => import("./../src/pages/blog.de.js" /* webpackChunkName: "component---src-pages-blog-de-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-demo-request-js": () => import("./../src/pages/demo-request.js" /* webpackChunkName: "component---src-pages-demo-request-js" */),
  "component---src-pages-index-de-js": () => import("./../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../src/pages/index.old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-learning-centre-js": () => import("./../src/pages/learning-centre.js" /* webpackChunkName: "component---src-pages-learning-centre-js" */),
  "component---src-pages-legal-js": () => import("./../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-security-js": () => import("./../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-updates-js": () => import("./../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

